import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService, DataService, MixpanelService, UserService } from '../services/index';
import { LocationStrategy } from '@angular/common';
import { Error } from '../models/index'
import { Base64 } from 'js-base64';
import { PixelService } from '../pixel.service';
import moengage from "@moengage/web-sdk";
@Component({
  selector: 'app-review-pay',
  templateUrl: './review-pay.component.html',
  styleUrls: ['./review-pay.component.css']
})
export class ReviewPayComponent implements OnInit {

  paytotalfare:any;
  farecurrency:any;
  bookingdata:any;
  bookingdetails:any;
  return_trip:any;
  is_return=false;
  loading=false;
    isCard=false;
    isMpesa=false;
    isVooma=false;
  public isCollapsed = true;
  public isCollapsedVooma = true;
  public isCollapsedCard = true;
  paymentType:any;
  isError:boolean=false;
  errorMessage:any;
  res:any;
  api_error:Error;
  voucherCode:string;
  hideFeedbackBtn=true;

  policyAmount=0;
  constructor(
    private router: Router,
    private users: UserService,
    private service:PaymentService,
    private dataService:DataService,
    private locationStrategy: LocationStrategy,
    private mixpanelService: MixpanelService,
  ) { }

  ngOnInit(): void {
    this.isCollapsedCard=false
    this.isMpesa=true;
    window.scroll(0,0);
    this.dataService.reviewPaymentValueChange.subscribe(message =>{
      if(!message){
        message = localStorage.getItem('review-buupass')
      }else{
        localStorage.setItem('review-buupass', message)
      }
      let encoded =JSON.parse(Base64.decode(message))
      this.bookingdata =  JSON.parse(Base64.decode(encoded.bookingdata));
      this.bookingdetails = JSON.parse(Base64.decode(encoded.bookingdetails));
      this.return_trip = JSON.parse(Base64.decode(encoded.returndata));
      

      this.paytotalfare=encoded.paytotalfare;
      this.farecurrency=this.bookingdetails.fare_currency
      this.policyAmount=encoded.policyAmount;
      this.is_return =  encoded.is_return;

    });

    this.isCollapsed = false;
    this.isCollapsedCard=true
    this.isCollapsedVooma=true
    this.paymentType="Mpesa";
    this.isCard=false;
    this.isMpesa=true;
    this.isVooma=false;

    
    this.mixpanelService.track('PageView', {
      pageName: 'Payment Page',
      source: 'buupassSite',
      role: 'customer',
    });
    
  }
  ngAfterViewInit(): void {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
  tConvert(time) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }
  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
  }
isCollapsedCardClicked(){
  this.isCollapsedCard = !this.isCollapsedCard
  this.isCollapsedVooma=true
  this.isCollapsed=true
  this.paymentType="Card";
  this.isCard=true;
  this.isMpesa=false;
  this.isVooma=false;
}
isCollapsedVoomaClicked(){
  this.isCollapsedVooma = !this.isCollapsedVooma
  this.isCollapsedCard=true
  this.isCollapsed=true
  this.paymentType="Vooma";
  this.isCard=false;
  this.isMpesa=false;
  this.isVooma=true;
}
isCollapsedClicked(){
  this.isCollapsed = this.isCollapsed
  this.isCollapsedCard=false
  this.isCollapsedVooma=true
  this.paymentType="Mpesa";
  this.isCard=false;
  this.isMpesa=true;
  this.isVooma=false;
}

submitBooking(){
this.paymentType="Mpesa";
if(this.paymentType){
  let bookingData:any;
  if(this.voucherCode){
    this.bookingdata.promo_code=this.voucherCode;
  }
  if(this.is_return){
    this.bookingdata.payment_channel=this.paymentType
    this.return_trip.payment_channel=this.paymentType
    bookingData={
      "initial_trip":this.bookingdata,
      "return_trip":this.return_trip
    }
  }else{
    bookingData=this.bookingdata;
    this.bookingdata.payment_channel=this.paymentType
  }

  this.loading=true;
  this.service.submitBooking(bookingData,this.bookingdetails.result.operator).subscribe(
    data  => {
      this.res=data.data;
      if(data.status == true){
        let others={
          'data':this.res,
          'payment_type':this.paymentType,
          'trip_id':this.bookingdetails.result.trip_id,
          'fullname':this.bookingdata.payee.name,
          'email':this.bookingdata.payee.email,
          'phoneno':this.bookingdata.payee.phone_number,
          'depature_date':this.bookingdetails.result.departure_date,
          'departure_time':this.bookingdetails.result.departure_time,
          'arrival_time':this.bookingdetails.result.arrival_time,
          'from':this.bookingdetails.result.from,
          'to':this.bookingdetails.result.to,
          'search_from':this.bookingdetails.search_from,
          'search_to':this.bookingdetails.search_to,
          "passengers":  this.bookingdata.passengers,
          "pickup": this.bookingdetails.search_details.pickup,
          "dropoff": this.bookingdetails.search_details.dropoff,
          "operator": this.bookingdetails.result.operator,
          "is_shuttle":this.bookingdetails.result.is_shuttle,
          'total_fare':this.paytotalfare,
          'drop_off_point': this.bookingdetails.drop_off_point,
          'date_of_travel': this.bookingdetails.date_of_travel
        };

        let phashed=this.users.hashPhoneNumber(this.bookingdata.payee.phone_number)
        let isLoggedIn= localStorage.getItem('isLoggedIn');
        if(isLoggedIn ){
          // moengage.update_unique_user_id(phashed);
        }else{
          moengage.add_unique_user_id('254' + this.bookingdata.payee.phone_number.slice(-9));
        }
        

        moengage.track_event('Payment Initiated', {
          'BookingId':this.res.booking_id,
          'PassengerName': this.bookingdata.payee.name,
          'PassengerEmail': this.bookingdata.payee.email,
          'PassengerPhone': '254' + this.bookingdata.payee.phone_number.slice(-9),
          'PassengerResidence': this.bookingdetails.search_from,
          'Busoperator': this.bookingdetails.result.operator.alias,
          'TotalFare':parseInt(this.paytotalfare),
          'TotalPassengers':this.bookingdata.passengers.length
        });

        let f=Base64.encode(JSON.stringify(others))
        localStorage.setItem('check-buupass',f);
        this.router.navigate(['/confirm-payments', f])
      }else{
        this.res.
        this.loading=false;
        this.isError=true;
        this.errorMessage=this.res.message;
      }

    },
    error  => {

      this.loading=false;
      this.isError=true;
      if(error.status==500){
        this.errorMessage="Sorry there has been a technical problem.Please try again ";
      }else{
        this.api_error=new Error().getTheMessage(error.error.errors)
        this.errorMessage=this.api_error;
      }

    }

    );
}
}

hideFeedback(){
  this.hideFeedbackBtn=true;
}


}
